<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import type { UspAnimation } from "../";
import { SvgIcon } from "../";

interface Props {
	items: string[];
	animation: UspAnimation;
	time?: number;
}

const { time = 5000, items, animation } = defineProps<Props>();

const shownIndex = ref<number>(0);
const timer = ref();

const enterAnimations = {
	"slide-left": "translate-x-full",
	"slide-right": "-translate-x-full",
	"slide-down": "-translate-y-full",
	"slide-up": "translate-y-full"
};

const leaveAnimations = {
	"slide-left": "-translate-x-full",
	"slide-right": "translate-x-full",
	"slide-down": "translate-y-full",
	"slide-up": "-translate-y-full"
};

onMounted(() => {
	if (items.length > 1) {
		timer.value = setInterval(() => {
			shownIndex.value === items.length - 1 ? (shownIndex.value = 0) : shownIndex.value++;
		}, time);
	}
});

onBeforeUnmount(() => clearInterval(timer.value));
</script>

<template>
	<section class="bg-yellow">
		<div
			class="container overflow-hidden grid items-center lg:grid-cols-5 gap-x-1 min-h-[45px] w-full h-full relative"
		>
			<div class="hidden lg:flex justify-start">
				<slot name="left" />
			</div>
			<div
				class="relative flex justify-center max-w-lg h-[45px] lg:col-span-3 items-center w-full mx-auto pointer-events-none"
			>
				<TransitionGroup
					:enter-from-class="`${enterAnimations[animation]} opacity-0`"
					enter-to-class="translate-0 opacity-100"
					enter-active-class="delay-300 duration-300 ease-in-out"
					leave-from-class="translate-0 opacity-100"
					:leave-to-class="`${leaveAnimations[animation]} opacity-0`"
					leave-active-class="absolute duration-300 ease-in-out"
				>
					<template v-for="(item, index) in items" :key="item">
						<span v-show="shownIndex === index" class="text-xs font-bold">
							{{ item }}
						</span>
					</template>
				</TransitionGroup>
				<SvgIcon
					name="chicken"
					class="text-[#A78B07] -scale-x-100 absolute bottom-0 -left-6 min-[400px]:left-0 xl:left-8 w-9"
				/>
			</div>
			<div class="hidden lg:flex justify-end">
				<slot name="right" />
			</div>
		</div>
	</section>
</template>
